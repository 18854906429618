import React from 'react'

class Footer extends React.Component {
  render() {
    return (<footer className="footer">
    Built by Kate Harold || Last Updated: February 6, 2024
        </footer>);
  }
}

export default Footer
