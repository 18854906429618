export const photos = [
  {
    src: "https://personal-site-bucket-ktharold.s3.us-east-2.amazonaws.com/grad.jpg",
    width: 3,
    height: 2
  },

  {
    src: "https://personal-site-bucket-ktharold.s3.us-east-2.amazonaws.com/cherry.JPG",
    width: 2,
    height: 3
  },

  {
    src: "https://personal-site-bucket-ktharold.s3.us-east-2.amazonaws.com/pickleball.png",
    width: 2,
    height: 3
  },

  {
    src :"https://personal-site-bucket-ktharold.s3.us-east-2.amazonaws.com/tahoe_scenery.jpeg",
    width: 3,
    height: 2
  },

  {
    src: "https://personal-site-bucket-ktharold.s3.us-east-2.amazonaws.com/big_sur.jpeg",
    width: 4,
    height: 3
  },

  {
    src: "https://personal-site-bucket-ktharold.s3.us-east-2.amazonaws.com/tahoe.jpeg",
    width: 3,
    height: 4
  }
];
